import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Container,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Chip,
  IconButton,
  Alert,
} from '@mui/material';
import { Visibility } from '@mui/icons-material';
import axios from 'axios';

interface VendorApplication {
  id: string;
  businessName: string;
  farmLocation: string;
  productTypes: string[];
  certifications: string;
  contactEmail: string;
  status: 'PENDING' | 'APPROVED' | 'REJECTED';
  submittedAt: string;
}

const VendorApplications: React.FC = () => {
  const [applications, setApplications] = useState<VendorApplication[]>([]);
  const [selectedApp, setSelectedApp] = useState<VendorApplication | null>(null);
  const [feedback, setFeedback] = useState('');
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    fetchApplications();
  }, []);

  const fetchApplications = async () => {
    try {
      const response = await axios.get('/api/admin/vendor-applications');
      setApplications(response.data);
    } catch (err) {
      setError('Failed to fetch applications');
    }
  };

  const handleViewDetails = (application: VendorApplication) => {
    setSelectedApp(application);
  };

  const handleClose = () => {
    setSelectedApp(null);
    setFeedback('');
  };

  const handleStatusUpdate = async (status: 'APPROVED' | 'REJECTED') => {
    if (!selectedApp) return;

    try {
      await axios.put(`/api/admin/vendor-applications/${selectedApp.id}`, {
        status,
        feedback,
      });
      await fetchApplications();
      handleClose();
    } catch (err) {
      setError('Failed to update application status');
    }
  };

  const getStatusColor = (status: string) => {
    switch (status) {
      case 'APPROVED':
        return 'success';
      case 'REJECTED':
        return 'error';
      default:
        return 'warning';
    }
  };

  return (
    <Container maxWidth="lg">
      <Paper elevation={3} sx={{ p: 4, mt: 4 }}>
        <Typography variant="h4" gutterBottom>
          Vendor Applications
        </Typography>

        {error && (
          <Alert severity="error" sx={{ mb: 3 }}>
            {error}
          </Alert>
        )}

        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Business Name</TableCell>
                <TableCell>Location</TableCell>
                <TableCell>Product Types</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Submitted</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {applications.map((app) => (
                <TableRow key={app.id}>
                  <TableCell>{app.businessName}</TableCell>
                  <TableCell>{app.farmLocation}</TableCell>
                  <TableCell>
                    {app.productTypes.map((type) => (
                      <Chip
                        key={type}
                        label={type}
                        size="small"
                        sx={{ mr: 0.5, mb: 0.5 }}
                      />
                    ))}
                  </TableCell>
                  <TableCell>
                    <Chip
                      label={app.status}
                      color={getStatusColor(app.status) as any}
                      size="small"
                    />
                  </TableCell>
                  <TableCell>
                    {new Date(app.submittedAt).toLocaleDateString()}
                  </TableCell>
                  <TableCell>
                    <IconButton
                      onClick={() => handleViewDetails(app)}
                      color="primary"
                      size="small"
                    >
                      <Visibility />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        <Dialog open={!!selectedApp} onClose={handleClose} maxWidth="md" fullWidth>
          <DialogTitle>Application Details</DialogTitle>
          <DialogContent>
            {selectedApp && (
              <Box sx={{ mt: 2 }}>
                <Typography variant="h6">{selectedApp.businessName}</Typography>
                <Typography variant="body1" gutterBottom>
                  Location: {selectedApp.farmLocation}
                </Typography>
                <Typography variant="body1" gutterBottom>
                  Email: {selectedApp.contactEmail}
                </Typography>
                <Typography variant="body1" gutterBottom>
                  Product Types: {selectedApp.productTypes.join(', ')}
                </Typography>
                <Typography variant="body1" gutterBottom>
                  Certifications: {selectedApp.certifications || 'None'}
                </Typography>
                {selectedApp.status === 'PENDING' && (
                  <TextField
                    fullWidth
                    label="Feedback (optional)"
                    multiline
                    rows={4}
                    value={feedback}
                    onChange={(e) => setFeedback(e.target.value)}
                    sx={{ mt: 2 }}
                  />
                )}
              </Box>
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Close</Button>
            {selectedApp?.status === 'PENDING' && (
              <>
                <Button
                  onClick={() => handleStatusUpdate('REJECTED')}
                  color="error"
                  variant="contained"
                >
                  Reject
                </Button>
                <Button
                  onClick={() => handleStatusUpdate('APPROVED')}
                  color="success"
                  variant="contained"
                >
                  Approve
                </Button>
              </>
            )}
          </DialogActions>
        </Dialog>
      </Paper>
    </Container>
  );
};

export default VendorApplications;
