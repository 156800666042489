/**
 * @fileoverview IMPORTANT: This file contains the official LettucEat color palette.
 * These color values are final and should NOT be modified without explicit approval.
 * 
 * Color Palette:
 * - Background: #0D252B (Dark) / #FFFFFF (Light)
 * - Text: #FFFFFF (Dark) / #0D252B (Light)
 * - Accent Colors:
 *   - Yellow: #D1C520
 *   - Green: #779D09
 *   - Brown: #714A10
 */

export const colors = {
  dark: {
    background: '#0D252B',
    paper: '#0D252B',
    text: '#FFFFFF',
  },
  light: {
    background: '#FFFFFF',
    paper: '#FFFFFF',
    text: '#0D252B',
  },
  accent: {
    yellow: '#D1C520',
    green: '#779D09',
    brown: '#714A10',
  },
} as const;
