import React from 'react';
import { Box, Container, Typography, Grid, Paper, useTheme } from '@mui/material';
import { Public, LocalAtm, EmojiNature, HealthAndSafety, People, School } from '@mui/icons-material';
import { colors } from '../../theme/colors';

interface ImpactCard {
  icon: React.ElementType;
  title: string;
  description: string;
}

const VisionSection: React.FC = () => {
  const theme = useTheme();
  const mode = theme.palette.mode;

  const impactCards: ImpactCard[] = [
    {
      icon: LocalAtm,
      title: "Strengthening Local Economies",
      description: "Supporting local farmers and strengthening rural communities by removing middlemen and ensuring fair profits."
    },
    {
      icon: Public,
      title: "Reducing Environmental Impact",
      description: "Cutting food miles and emissions by connecting consumers directly with local producers."
    },
    {
      icon: People,
      title: "Fighting Food Insecurity",
      description: "Making fresh produce accessible and facilitating donations to local food banks."
    },
    {
      icon: HealthAndSafety,
      title: "Promoting Healthier Lifestyles",
      description: "Empowering people to choose fresh, nutrient-rich local produce over processed alternatives."
    },
    {
      icon: EmojiNature,
      title: "Empowering Small Growers",
      description: "Leveling the playing field for backyard gardeners and small-scale farmers to reach consumers."
    },
    {
      icon: School,
      title: "Educating About Food Origins",
      description: "Bridging the gap between consumers and their food sources through transparency and education."
    }
  ];

  return (
    <Box 
      sx={{ 
        py: 8,
        bgcolor: mode === 'dark' ? 'rgba(13, 37, 43, 0.7)' : 'rgba(255, 255, 255, 0.9)',
      }}
    >
      <Container maxWidth="lg">
        <Typography
          variant="h2"
          align="center"
          sx={{
            color: mode === 'dark' ? colors.dark.text : colors.light.text,
            mb: 2,
            fontFamily: 'HKYavimayan',
          }}
        >
          Our Vision
        </Typography>
        
        <Typography
          variant="h5"
          align="center"
          sx={{
            color: mode === 'dark' ? colors.dark.text : colors.light.text,
            mb: 6,
            maxWidth: 800,
            mx: 'auto'
          }}
        >
          LettuceEat is more than an app—it's a movement. We're building a bridge between local farmers,
          gardeners, and consumers, transforming the way people access fresh, sustainable, and local food.
        </Typography>

        <Grid container spacing={4}>
          {impactCards.map((card, index) => {
            const Icon = card.icon;
            return (
              <Grid item xs={12} md={4} key={index}>
                <Paper
                  sx={{
                    p: 3,
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    textAlign: 'center',
                    bgcolor: mode === 'dark' ? 'rgba(13, 37, 43, 0.5)' : 'rgba(255, 255, 255, 0.9)',
                    border: `1px solid ${mode === 'dark' ? 'rgba(255, 255, 255, 0.1)' : 'rgba(0, 0, 0, 0.1)'}`,
                    transition: 'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out',
                    '&:hover': {
                      transform: 'translateY(-8px)',
                      boxShadow: theme.shadows[8]
                    }
                  }}
                >
                  <Icon
                    sx={{
                      fontSize: 48,
                      color: colors.accent.green,
                      mb: 2
                    }}
                  />
                  <Typography
                    variant="h5"
                    component="h3"
                    sx={{
                      color: mode === 'dark' ? colors.dark.text : colors.light.text,
                      mb: 2,
                      fontFamily: 'HKYavimayan'
                    }}
                  >
                    {card.title}
                  </Typography>
                  <Typography
                    sx={{
                      color: mode === 'dark' ? colors.dark.text : colors.light.text,
                      flex: 1
                    }}
                  >
                    {card.description}
                  </Typography>
                </Paper>
              </Grid>
            );
          })}
        </Grid>
      </Container>
    </Box>
  );
};

export default VisionSection;
