import React, { useState, useEffect } from 'react';
import { Box, Container, Typography, Paper, TextField, Button, useTheme, Link, Divider, Alert, CircularProgress } from '@mui/material';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import GoogleIcon from '@mui/icons-material/Google';
import { supabase } from '../../lib/supabase';
import { useAuth } from '../../contexts/AuthContext';

const Login: React.FC = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { user } = useAuth();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  // Redirect if already logged in
  useEffect(() => {
    console.log('Login useEffect - Current user:', user);
    if (user) {
      console.log('User role:', user.user_role);
      if (user.user_role === 'admin') {
        console.log('Navigating to admin...');
        navigate('/admin', { replace: true });
      } else {
        console.log('Navigating to home...');
        navigate('/', { replace: true });
      }
    }
  }, [user, navigate]);

  const handleEmailLogin = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!email || !password) {
      setError('Please enter both email and password');
      return;
    }

    try {
      setLoading(true);
      setError(null);

      console.log('Attempting email login...');
      const { data, error } = await supabase.auth.signInWithPassword({
        email,
        password
      });

      console.log('Login response:', { data, error });

      if (error) throw error;
      
      // Navigation will be handled by the useEffect when user state updates
      console.log('Login successful, waiting for auth state update...');

      // Set a timeout to clear loading state if navigation doesn't happen
      setTimeout(() => {
        setLoading(false);
      }, 5000); // 5 seconds timeout
    } catch (err) {
      console.error('Email login error:', err);
      setError(err instanceof Error ? err.message : 'Invalid email or password');
      setLoading(false);
    }
  };

  const handleGoogleLogin = async () => {
    try {
      setLoading(true);
      setError(null);
      
      console.log('Starting Google OAuth login...');
      const { data, error } = await supabase.auth.signInWithOAuth({
        provider: 'google',
        options: {
          redirectTo: `${window.location.origin}/auth/callback`,
          queryParams: {
            access_type: 'offline',
            prompt: 'consent'
          }
        }
      });

      if (error) {
        console.error('Google OAuth error:', error);
        throw error;
      }
      
      console.log('Google OAuth initiated successfully:', {
        provider: 'google',
        redirectUrl: `${window.location.origin}/auth/callback`,
        data
      });
    } catch (err) {
      console.error('Error signing in with Google:', err);
      setError('Error signing in with Google. Please try again.');
      setLoading(false);
    }
  };

  return (
    <Container maxWidth="sm">
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          minHeight: '80vh',
        }}
      >
        <Paper
          elevation={0}
          sx={{
            p: 4,
            width: '100%',
            borderRadius: 2,
            backgroundColor: theme.palette.mode === 'dark' ? '#1B2B2B' : '#FFFFFF',
            border: '1px solid',
            borderColor: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.1)' : 'rgba(0, 0, 0, 0.1)',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              gap: 3,
            }}
          >
            <Typography
              component="h1"
              variant="h4"
              sx={{
                fontFamily: 'HKYavimayan, Arial, sans-serif',
                color: '#90EE90',
                fontWeight: 'bold',
                mb: 2,
              }}
            >
              Welcome Back!
            </Typography>

            <TextField
              label="Email"
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              fullWidth
              required
              disabled={loading}
              sx={{
                borderColor: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.1)' : 'rgba(0, 0, 0, 0.1)',
              }}
            />

            <TextField
              label="Password"
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              fullWidth
              required
              disabled={loading}
              sx={{
                borderColor: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.1)' : 'rgba(0, 0, 0, 0.1)',
              }}
            />

            <Button
              type="submit"
              variant="contained"
              color="primary"
              size="large"
              disabled={loading}
              onClick={handleEmailLogin}
              sx={{
                py: 1.5,
                borderColor: '#90EE90',
                color: theme.palette.mode === 'dark' ? '#FFFFFF' : '#000000',
                '&:hover': {
                  borderColor: '#74C274',
                  backgroundColor: 'rgba(144, 238, 144, 0.1)',
                },
              }}
            >
              {loading ? (
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                  <CircularProgress size={20} color="inherit" />
                  <span>Logging in...</span>
                </Box>
              ) : (
                'Login'
              )}
            </Button>

            <Divider sx={{ my: 2 }}>OR</Divider>

            <Button
              onClick={handleGoogleLogin}
              variant="outlined"
              color="primary"
              size="large"
              disabled={loading}
              startIcon={<GoogleIcon />}
              sx={{
                py: 1.5,
                borderColor: '#90EE90',
                color: theme.palette.mode === 'dark' ? '#FFFFFF' : '#000000',
                '&:hover': {
                  borderColor: '#74C274',
                  backgroundColor: 'rgba(144, 238, 144, 0.1)',
                },
              }}
            >
              Continue with Google
            </Button>

            <Typography
              variant="body2"
              color="textSecondary"
              align="center"
              sx={{ mt: 2 }}
            >
              Don't have an account?{' '}
              <Link component={RouterLink} to="/register">
                Sign up
              </Link>
            </Typography>
          </Box>
        </Paper>
      </Box>
    </Container>
  );
};

export default Login;
