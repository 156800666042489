import { createClient, AuthChangeEvent, Session } from '@supabase/supabase-js';
import { APP_URL } from '../config/urls';

const supabaseUrl = process.env.REACT_APP_SUPABASE_URL!;
const supabaseAnonKey = process.env.REACT_APP_SUPABASE_ANON_KEY!;

// Debug logging
console.log('Initializing Supabase client with URL:', supabaseUrl);

export const supabase = createClient(supabaseUrl, supabaseAnonKey, {
  auth: {
    autoRefreshToken: true,
    persistSession: true,
    detectSessionInUrl: true,
    flowType: 'implicit',
    storage: window.localStorage,
    storageKey: 'supabase.auth.token',
    debug: true
  },
  global: {
    headers: {
      'x-client-info': 'lettuce-eat-kansas@1.0.0'
    }
  }
});

// Log when auth state changes
supabase.auth.onAuthStateChange((event, session) => {
  console.log('Auth state changed:', { event, session: session ? 'exists' : 'null' });
});

// Initialize session from URL if present
export const initializeAuthFromUrl = async () => {
  try {
    const { data, error } = await supabase.auth.getSession();
    if (error) throw error;
    return data.session;
  } catch (err) {
    console.error('Error initializing auth from URL:', err);
    return null;
  }
};

// Vendor applications
export const vendorApplications = {
  create: async (application: any) => {
    const { data, error } = await supabase
      .from('vendor_applications')
      .insert([application])
      .select();
    if (error) throw error;
    return data;
  },

  getAll: async () => {
    const { data, error } = await supabase
      .from('vendor_applications')
      .select('*');
    if (error) throw error;
    return data;
  },

  updateStatus: async (id: number, status: string) => {
    const { error } = await supabase
      .from('vendor_applications')
      .update({ status })
      .eq('id', id);
    if (error) throw error;
  }
};

// Orders management
export const orders = {
  getAll: async () => {
    const { data, error } = await supabase
      .from('orders')
      .select('*');
    if (error) throw error;
    return data;
  },

  updateStatus: async (id: number, status: string) => {
    const { error } = await supabase
      .from('orders')
      .update({ status })
      .eq('id', id);
    if (error) throw error;
  },

  processRefund: async (orderId: number, amount: number) => {
    const { error } = await supabase
      .from('refunds')
      .insert([{
        order_id: orderId,
        amount: amount,
        status: 'pending'
      }]);
    if (error) throw error;
  }
};

// Audit logging
export const auditLogs = {
  create: async (action: string, details: any, userId: string) => {
    const { error } = await supabase
      .from('audit_logs')
      .insert([{
        action,
        details,
        user_id: userId
      }]);
    if (error) throw error;
  },

  getAll: async () => {
    const { data, error } = await supabase
      .from('audit_logs')
      .select('*');
    if (error) throw error;
    return data;
  }
};
