import React from 'react';
import { Box, Container, Typography, Paper, useTheme, Avatar, Grid, TextField, Button } from '@mui/material';
import { useAuth } from '../../contexts/AuthContext';

const Profile: React.FC = () => {
  const theme = useTheme();
  const { user } = useAuth();

  return (
    <Box sx={{ 
      minHeight: '100vh',
      bgcolor: theme.palette.background.default,
      color: theme.palette.text.primary,
      pt: 8
    }}>
      <Container maxWidth="md">
        <Box sx={{ 
          display: 'flex', 
          flexDirection: 'column', 
          gap: 4 
        }}>
          <Typography 
            component="h1" 
            variant="h2" 
            sx={{ 
              fontFamily: 'HKYavimayan',
              color: theme.palette.primary.main,
              mb: 4,
              textAlign: 'center'
            }}
          >
            Profile
          </Typography>

          <Paper 
            elevation={3} 
            sx={{ 
              p: 4,
              bgcolor: theme.palette.background.paper,
              borderRadius: 2
            }}
          >
            <Grid container spacing={4}>
              <Grid item xs={12} md={4} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 2 }}>
                <Avatar 
                  src={user?.user_metadata?.avatar_url}
                  sx={{ 
                    width: 120, 
                    height: 120,
                    border: `3px solid ${theme.palette.primary.main}`
                  }}
                />
                <Button 
                  variant="outlined" 
                  sx={{ 
                    color: theme.palette.primary.main,
                    borderColor: theme.palette.primary.main
                  }}
                >
                  Change Photo
                </Button>
              </Grid>

              <Grid item xs={12} md={8}>
                <Box component="form" sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
                  <TextField
                    fullWidth
                    label="Full Name"
                    defaultValue={user?.user_metadata?.full_name}
                    variant="outlined"
                  />
                  <TextField
                    fullWidth
                    label="Email"
                    defaultValue={user?.email}
                    variant="outlined"
                    disabled
                  />
                  <TextField
                    fullWidth
                    label="Phone Number"
                    defaultValue={user?.user_metadata?.phone}
                    variant="outlined"
                  />
                  
                  <Button
                    variant="contained"
                    sx={{ 
                      bgcolor: theme.palette.primary.main,
                      color: '#fff',
                      '&:hover': {
                        bgcolor: theme.palette.primary.dark,
                      }
                    }}
                  >
                    Save Changes
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Paper>
        </Box>
      </Container>
    </Box>
  );
};

export default Profile;
