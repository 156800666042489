import { createTheme, PaletteMode, Components, Theme } from '@mui/material/styles';
import { colors } from './colors';

declare module '@mui/material/styles' {
  interface Palette {
    lettuce: {
      main: string;
      dark: string;
      light: string;
    };
  }
  interface PaletteOptions {
    lettuce?: {
      main: string;
      dark: string;
      light: string;
    };
  }
}

const getBaseComponents = (mode: PaletteMode): Components<Omit<Theme, "components">> => ({
  MuiCssBaseline: {
    styleOverrides: `
      @font-face {
        font-family: 'HKYavimayan';
        src: url('/fonts/HKYavimayan-Regular.otf') format('opentype');
        font-weight: normal;
        font-style: normal;
      }
      body {
        background-color: ${mode === 'dark' ? colors.dark.background : colors.light.background};
        color: ${mode === 'dark' ? colors.dark.text : colors.light.text};
        min-height: 100vh;
        margin: 0;
      }
    `,
  },
  MuiButton: {
    defaultProps: {
      disableElevation: true,
    },
    styleOverrides: {
      root: {
        textTransform: 'none',
        borderRadius: 8,
        padding: '8px 16px',
        transition: 'all 0.2s ease-in-out',
      },
      contained: {
        backgroundColor: colors.accent.green,
        color: colors.light.text,
        '&:hover': {
          backgroundColor: colors.accent.yellow,
          transform: 'translateY(-2px)',
          boxShadow: '0 5px 15px rgba(209, 197, 32, 0.3)',
        },
      },
      outlined: {
        borderColor: colors.accent.green,
        color: mode === 'dark' ? colors.dark.text : colors.light.text,
        '&:hover': {
          borderColor: colors.accent.yellow,
          backgroundColor: mode === 'dark' ? 'rgba(209, 197, 32, 0.1)' : 'rgba(209, 197, 32, 0.1)',
          transform: 'translateY(-2px)',
        },
      },
      text: {
        color: mode === 'dark' ? colors.dark.text : colors.light.text,
        '&:hover': {
          backgroundColor: mode === 'dark' ? 'rgba(255, 255, 255, 0.05)' : 'rgba(13, 37, 43, 0.05)',
        },
      },
    },
  },
  MuiCard: {
    styleOverrides: {
      root: {
        backgroundColor: mode === 'dark' ? colors.dark.background : colors.light.background,
        color: mode === 'dark' ? colors.dark.text : colors.light.text,
        transition: 'all 0.3s ease-in-out',
        '&:hover': {
          transform: 'translateY(-5px)',
          boxShadow: mode === 'dark' 
            ? '0 8px 20px rgba(0, 0, 0, 0.3)' 
            : '0 8px 20px rgba(13, 37, 43, 0.1)',
        },
      },
    },
  },
  MuiPaper: {
    styleOverrides: {
      root: {
        backgroundColor: mode === 'dark' ? colors.dark.background : colors.light.background,
        color: mode === 'dark' ? colors.dark.text : colors.light.text,
        transition: 'all 0.3s ease-in-out',
      },
    },
  },
  MuiAppBar: {
    styleOverrides: {
      root: {
        backgroundColor: mode === 'dark' ? colors.dark.background : colors.light.background,
        color: mode === 'dark' ? colors.dark.text : colors.light.text,
        boxShadow: 'none',
        borderBottom: mode === 'dark'
          ? '1px solid rgba(255, 255, 255, 0.1)'
          : '1px solid rgba(13, 37, 43, 0.1)',
      },
    },
  },
  MuiTypography: {
    styleOverrides: {
      root: {
        color: mode === 'dark' ? colors.dark.text : colors.light.text,
      },
    },
  },
  MuiIconButton: {
    styleOverrides: {
      root: {
        color: mode === 'dark' ? colors.dark.text : colors.light.text,
        '&:hover': {
          backgroundColor: mode === 'dark' ? 'rgba(255, 255, 255, 0.05)' : 'rgba(13, 37, 43, 0.05)',
        },
      },
    },
  },
  MuiLink: {
    styleOverrides: {
      root: {
        color: colors.accent.green,
        '&:hover': {
          color: colors.accent.yellow,
        },
      },
    },
  },
  MuiDivider: {
    styleOverrides: {
      root: {
        borderColor: mode === 'dark' ? 'rgba(255, 255, 255, 0.1)' : 'rgba(13, 37, 43, 0.1)',
      },
    },
  },
});

const getTheme = (mode: PaletteMode) => {
  const baseTheme = {
    palette: {
      mode,
      primary: {
        main: colors.accent.green,
        light: colors.accent.yellow,
        dark: colors.accent.brown,
      },
      background: {
        default: mode === 'dark' ? colors.dark.background : colors.light.background,
        paper: mode === 'dark' ? colors.dark.background : colors.light.background,
      },
      text: {
        primary: mode === 'dark' ? colors.dark.text : colors.light.text,
        secondary: mode === 'dark' ? colors.dark.text : colors.light.text,
      },
      lettuce: {
        main: colors.accent.brown,
        light: colors.accent.yellow,
        dark: colors.accent.green,
      },
    },
    typography: {
      fontFamily: '"Roboto", "Arial", sans-serif',
      h1: {
        fontFamily: 'HKYavimayan, Arial, sans-serif',
        fontWeight: 700,
        color: mode === 'dark' ? colors.dark.text : colors.light.text,
      },
      h2: {
        fontFamily: 'HKYavimayan, Arial, sans-serif',
        fontWeight: 700,
        color: mode === 'dark' ? colors.dark.text : colors.light.text,
      },
      h3: {
        fontFamily: 'HKYavimayan, Arial, sans-serif',
        fontWeight: 600,
        color: mode === 'dark' ? colors.dark.text : colors.light.text,
      },
      h4: {
        fontFamily: 'HKYavimayan, Arial, sans-serif',
        fontWeight: 600,
        color: mode === 'dark' ? colors.dark.text : colors.light.text,
      },
      h5: {
        fontFamily: 'HKYavimayan, Arial, sans-serif',
        fontWeight: 500,
        color: mode === 'dark' ? colors.dark.text : colors.light.text,
      },
      h6: {
        fontFamily: 'HKYavimayan, Arial, sans-serif',
        fontWeight: 500,
        color: mode === 'dark' ? colors.dark.text : colors.light.text,
      },
      body1: {
        color: mode === 'dark' ? colors.dark.text : colors.light.text,
      },
      body2: {
        color: mode === 'dark' ? colors.dark.text : colors.light.text,
      },
    },
    components: getBaseComponents(mode),
  };

  return createTheme(baseTheme);
};

export default getTheme;
