import React, { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import {
  AppBar,
  Box,
  Toolbar,
  IconButton,
  Typography,
  Menu,
  Container,
  Avatar,
  Button,
  MenuItem,
  CircularProgress,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import AccountCircle from '@mui/icons-material/AccountCircle';
import { useAuth } from '../../contexts/AuthContext';
import { supabase } from '../../lib/supabaseClient';
import ThemeToggle from '../ThemeToggle';

const Navbar: React.FC = () => {
  const { user, loading } = useAuth();
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = async () => {
    try {
      handleClose();
      // Clear any local storage items
      localStorage.removeItem('supabase.auth.token');
      // Sign out from Supabase
      const { error } = await supabase.auth.signOut();
      if (error) {
        console.error('Error during logout:', error.message);
        return;
      }
      // Navigate to home page
      window.location.href = '/';
    } catch (error) {
      console.error('Error logging out:', error);
    }
  };

  return (
    <AppBar 
      position="static" 
      sx={{ 
        bgcolor: theme.palette.mode === 'dark' ? 'background.paper' : 'primary.main',
        boxShadow: 'none',
        borderBottom: `1px solid ${theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.1)' : 'rgba(0, 0, 0, 0.1)'}`,
      }}
    >
      <Container maxWidth="lg">
        <Toolbar disableGutters sx={{ justifyContent: 'space-between' }}>
          <Typography
            variant="h6"
            component={RouterLink}
            to="/"
            sx={{
              fontFamily: 'HKYavimayan',
              textDecoration: 'none',
              color: theme.palette.mode === 'dark' ? 'primary.main' : 'white',
              fontSize: '1.5rem',
              fontWeight: 'bold',
            }}
          >
            LettuceEat
          </Typography>

          <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
            {loading ? (
              <CircularProgress size={24} sx={{ color: theme.palette.mode === 'dark' ? 'primary.main' : 'white' }} />
            ) : user ? (
              <>
                <Button
                  component={RouterLink}
                  to="/browse"
                  sx={{
                    color: theme.palette.mode === 'dark' ? 'text.primary' : 'white',
                    '&:hover': {
                      bgcolor: theme.palette.mode === 'dark' ? 'rgba(144, 238, 144, 0.1)' : 'rgba(255, 255, 255, 0.1)',
                    },
                  }}
                >
                  Browse
                </Button>
                <IconButton
                  onClick={handleMenu}
                  sx={{
                    color: theme.palette.mode === 'dark' ? 'text.primary' : 'white',
                  }}
                >
                  {user.user_metadata?.avatar_url ? (
                    <Avatar
                      src={user.user_metadata.avatar_url}
                      alt={user.email || ''}
                      sx={{ 
                        width: 32, 
                        height: 32,
                        border: `2px solid ${theme.palette.mode === 'dark' ? theme.palette.primary.main : 'white'}`
                      }}
                    />
                  ) : (
                    <AccountCircle />
                  )}
                </IconButton>
                <Menu
                  anchorEl={anchorEl}
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  PaperProps={{
                    sx: {
                      bgcolor: 'background.paper',
                      borderRadius: 2,
                      mt: 1,
                      minWidth: 180,
                      boxShadow: theme.shadows[4],
                    },
                  }}
                >
                  <MenuItem
                    component={RouterLink}
                    to="/profile"
                    onClick={handleClose}
                    sx={{
                      color: 'text.primary',
                      '&:hover': {
                        bgcolor: 'action.hover',
                      },
                    }}
                  >
                    Profile
                  </MenuItem>
                  <MenuItem
                    component={RouterLink}
                    to="/orders"
                    onClick={handleClose}
                    sx={{
                      color: 'text.primary',
                      '&:hover': {
                        bgcolor: 'action.hover',
                      },
                    }}
                  >
                    Orders
                  </MenuItem>
                  {user.user_role === 'admin' && (
                    <MenuItem
                      component={RouterLink}
                      to="/admin"
                      onClick={handleClose}
                      sx={{
                        color: 'text.primary',
                        '&:hover': {
                          bgcolor: 'action.hover',
                        },
                      }}
                    >
                      Admin Dashboard
                    </MenuItem>
                  )}
                  {user.user_role === 'vendor' && (
                    <MenuItem
                      component={RouterLink}
                      to="/merchant/dashboard"
                      onClick={handleClose}
                      sx={{
                        color: 'text.primary',
                        '&:hover': {
                          bgcolor: 'action.hover',
                        },
                      }}
                    >
                      Vendor Dashboard
                    </MenuItem>
                  )}
                  <MenuItem
                    onClick={handleLogout}
                    sx={{
                      color: 'error.main',
                      '&:hover': {
                        bgcolor: 'error.light',
                      },
                    }}
                  >
                    Logout
                  </MenuItem>
                </Menu>
              </>
            ) : (
              <>
                <Button
                  component={RouterLink}
                  to="/login"
                  variant="outlined"
                  sx={{
                    borderColor: theme.palette.mode === 'dark' ? 'primary.main' : 'white',
                    color: theme.palette.mode === 'dark' ? 'primary.main' : 'white',
                    '&:hover': {
                      borderColor: theme.palette.mode === 'dark' ? 'primary.light' : 'rgba(255, 255, 255, 0.8)',
                      bgcolor: theme.palette.mode === 'dark' ? 'rgba(144, 238, 144, 0.1)' : 'rgba(255, 255, 255, 0.1)',
                    },
                  }}
                >
                  Login
                </Button>
                <Button
                  component={RouterLink}
                  to="/register"
                  variant="contained"
                  sx={{
                    bgcolor: theme.palette.mode === 'dark' ? 'primary.main' : 'white',
                    color: theme.palette.mode === 'dark' ? 'background.paper' : 'primary.main',
                    '&:hover': {
                      bgcolor: theme.palette.mode === 'dark' ? 'primary.light' : 'rgba(255, 255, 255, 0.9)',
                    },
                  }}
                >
                  Register
                </Button>
              </>
            )}
            <ThemeToggle />
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default Navbar;