import React from 'react';
import { Box, Container, Typography, Paper } from '@mui/material';

const ForgotPassword: React.FC = () => {
  return (
    <Container maxWidth="sm">
      <Box sx={{ mt: 8, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Paper elevation={3} sx={{ p: 4, width: '100%' }}>
          <Typography component="h1" variant="h4" align="center" gutterBottom>
            Forgot Password
          </Typography>
          {/* Forgot password form will go here */}
        </Paper>
      </Box>
    </Container>
  );
};

export default ForgotPassword;
