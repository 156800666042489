import React, { useEffect, useState } from 'react';
import {
  Box,
  Container,
  Paper,
  Typography,
  Stepper,
  Step,
  StepLabel,
  Alert,
  CircularProgress,
} from '@mui/material';
import axios from 'axios';

interface ApplicationStatus {
  status: 'PENDING' | 'APPROVED' | 'REJECTED';
  submittedAt: string;
  updatedAt: string;
  feedback?: string;
}

const ApplicationStatusPage: React.FC = () => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [applicationStatus, setApplicationStatus] = useState<ApplicationStatus | null>(null);

  const steps = ['Submitted', 'Under Review', 'Decision'];

  useEffect(() => {
    const fetchStatus = async () => {
      try {
        const response = await axios.get('/api/vendor/application-status');
        setApplicationStatus(response.data);
      } catch (err) {
        setError('Failed to fetch application status');
      } finally {
        setLoading(false);
      }
    };

    fetchStatus();
  }, []);

  const getActiveStep = () => {
    if (!applicationStatus) return 0;
    switch (applicationStatus.status) {
      case 'PENDING':
        return 1;
      case 'APPROVED':
      case 'REJECTED':
        return 2;
      default:
        return 0;
    }
  };

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="60vh">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Container maxWidth="md">
      <Paper elevation={3} sx={{ p: 4, mt: 4 }}>
        <Typography variant="h4" gutterBottom>
          Application Status
        </Typography>

        {error ? (
          <Alert severity="error" sx={{ mb: 3 }}>
            {error}
          </Alert>
        ) : (
          <>
            <Stepper activeStep={getActiveStep()} alternativeLabel sx={{ mb: 4 }}>
              {steps.map((label) => (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>

            {applicationStatus && (
              <Box>
                <Typography variant="h6" color="primary" gutterBottom>
                  Status: {applicationStatus.status}
                </Typography>
                <Typography variant="body1" gutterBottom>
                  Submitted: {new Date(applicationStatus.submittedAt).toLocaleDateString()}
                </Typography>
                <Typography variant="body1" gutterBottom>
                  Last Updated: {new Date(applicationStatus.updatedAt).toLocaleDateString()}
                </Typography>
                {applicationStatus.feedback && (
                  <Alert severity="info" sx={{ mt: 2 }}>
                    <Typography variant="body1">Feedback: {applicationStatus.feedback}</Typography>
                  </Alert>
                )}
              </Box>
            )}
          </>
        )}
      </Paper>
    </Container>
  );
};

export default ApplicationStatusPage;
