import React, { useState } from 'react';
import {
  Box,
  Paper,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Chip,
  Stack,
} from '@mui/material';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { supabase } from '../../lib/supabase';
import LoadingSpinner from '../../components/common/LoadingSpinner';

interface VendorApplication {
  id: string;
  user_id: string;
  business_name: string;
  business_description: string;
  contact_email: string;
  contact_phone: string;
  location: string;
  product_types: string[];
  status: 'pending' | 'approved' | 'rejected';
  created_at: string;
  admin_notes: string;
  profiles: {
    email: string;
  };
}

const Applications = () => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [selectedApp, setSelectedApp] = useState<VendorApplication | null>(null);
  const [reviewDialogOpen, setReviewDialogOpen] = useState(false);
  const [status, setStatus] = useState<'pending' | 'approved' | 'rejected'>('pending');
  const [adminNotes, setAdminNotes] = useState('');
  const queryClient = useQueryClient();

  // Fetch applications
  const { data: applications, isLoading } = useQuery({
    queryKey: ['vendor-applications'],
    queryFn: async () => {
      const { data, error } = await supabase
        .from('vendor_applications')
        .select('*, profiles(email)')
        .order('created_at', { ascending: false });

      if (error) throw error;
      return data as VendorApplication[];
    }
  });

  // Update application status mutation
  const updateApplication = useMutation({
    mutationFn: async ({
      id,
      status,
      adminNotes,
    }: {
      id: string;
      status: 'approved' | 'rejected';
      adminNotes: string;
    }) => {
      const { data: updatedApp, error } = await supabase
        .from('vendor_applications')
        .update({ status, admin_notes: adminNotes })
        .eq('id', id)
        .select()
        .single();

      if (error) throw error;

      // If approved, update user role to vendor
      if (status === 'approved' && updatedApp) {
        const { error: roleError } = await supabase
          .from('profiles')
          .update({ user_role: 'vendor' })
          .eq('id', updatedApp.user_id);

        if (roleError) throw roleError;
      }

      // Log the action
      await supabase.from('audit_logs').insert({
        user_id: updatedApp.user_id,
        action: `Vendor application ${status}`,
        details: {
          application_id: id,
          status,
          admin_notes: adminNotes,
        },
      });

      return updatedApp;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['vendor-applications'] });
      handleCloseReviewDialog();
    }
  });

  const handleChangePage = (_event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleOpenReviewDialog = (application: VendorApplication) => {
    setSelectedApp(application);
    setStatus(application.status || 'pending');
    setAdminNotes(application.admin_notes || '');
    setReviewDialogOpen(true);
  };

  const handleCloseReviewDialog = () => {
    setReviewDialogOpen(false);
    setSelectedApp(null);
    setStatus('pending');
    setAdminNotes('');
  };

  const handleSubmitReview = async () => {
    if (!selectedApp || status === 'pending') return;

    updateApplication.mutate({
      id: selectedApp.id,
      status: status as 'approved' | 'rejected',
      adminNotes,
    });
  };

  if (isLoading) {
    return <LoadingSpinner />;
  }

  return (
    <Box>
      <Typography variant="h4" sx={{ mb: 4 }}>
        Vendor Applications
      </Typography>

      <Paper>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Business Name</TableCell>
                <TableCell>Contact Email</TableCell>
                <TableCell>Location</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Submitted</TableCell>
                <TableCell align="right">Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {applications
                ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((app) => (
                  <TableRow key={app.id}>
                    <TableCell>{app.business_name}</TableCell>
                    <TableCell>{app.contact_email}</TableCell>
                    <TableCell>{app.location}</TableCell>
                    <TableCell>
                      <Chip
                        label={app.status}
                        color={
                          app.status === 'approved'
                            ? 'success'
                            : app.status === 'rejected'
                            ? 'error'
                            : 'warning'
                        }
                        size="small"
                      />
                    </TableCell>
                    <TableCell>
                      {new Date(app.created_at).toLocaleDateString()}
                    </TableCell>
                    <TableCell align="right">
                      <Button
                        variant="outlined"
                        size="small"
                        onClick={() => handleOpenReviewDialog(app)}
                      >
                        Review
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={applications?.length || 0}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>

      {/* Review Dialog */}
      <Dialog
        open={reviewDialogOpen}
        onClose={handleCloseReviewDialog}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>Review Application</DialogTitle>
        <DialogContent>
          {selectedApp && (
            <Box sx={{ pt: 2, display: 'flex', flexDirection: 'column', gap: 2 }}>
              <Typography variant="subtitle1">
                Business Name: {selectedApp.business_name}
              </Typography>
              <Typography variant="body1">
                Description: {selectedApp.business_description}
              </Typography>
              <Stack direction="row" spacing={2}>
                <Typography>
                  Contact: {selectedApp.contact_email} | {selectedApp.contact_phone}
                </Typography>
              </Stack>
              <Typography>Location: {selectedApp.location}</Typography>
              <Stack direction="row" spacing={1}>
                <Typography>Products:</Typography>
                {selectedApp.product_types.map((type) => (
                  <Chip key={type} label={type} size="small" />
                ))}
              </Stack>
              <FormControl fullWidth sx={{ mb: 2 }}>
                <InputLabel>Status</InputLabel>
                <Select
                  value={status}
                  label="Status"
                  onChange={(e) => setStatus(e.target.value as 'pending' | 'approved' | 'rejected')}
                >
                  <MenuItem value="pending" disabled>Pending</MenuItem>
                  <MenuItem value="approved">Approve</MenuItem>
                  <MenuItem value="rejected">Reject</MenuItem>
                </Select>
              </FormControl>
              <TextField
                label="Admin Notes"
                multiline
                rows={4}
                value={adminNotes}
                onChange={(e) => setAdminNotes(e.target.value)}
                fullWidth
              />
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseReviewDialog}>Cancel</Button>
          <Button
            onClick={handleSubmitReview}
            variant="contained"
            disabled={updateApplication.isPending}
          >
            Submit Review
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default Applications;
