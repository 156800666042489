import React from 'react';
import { Box, Container, Typography, Paper, Button, Grid, useTheme } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { EmojiNature, LocalShipping, Storefront } from '@mui/icons-material';
import { colors } from '../../theme/colors';
import VisionSection from '../../components/Home/VisionSection';

const Home: React.FC = () => {
  const theme = useTheme();
  const mode = theme.palette.mode;

  const features = [
    {
      title: 'Farm Fresh',
      description: 'Experience the difference with our farm fresh options.',
      icon: EmojiNature,
    },
    {
      title: 'Local Delivery',
      description: 'Experience the difference with our local delivery options.',
      icon: LocalShipping,
    },
    {
      title: 'Sustainable Choice',
      description: 'Experience the difference with our sustainable choice options.',
      icon: Storefront,
    }
  ];

  return (
    <Box sx={{ 
      bgcolor: mode === 'dark' ? colors.dark.background : colors.light.background,
      color: mode === 'dark' ? colors.dark.text : colors.light.text,
      minHeight: '100vh' 
    }}>
      <Container maxWidth="lg">
        <Box sx={{ 
          display: 'flex', 
          flexDirection: 'column', 
          alignItems: 'center',
          textAlign: 'center',
          pt: 8,
          pb: 6
        }}>
          <Typography 
            variant="h1" 
            component="h1"
            sx={{ 
              color: mode === 'dark' ? colors.dark.text : colors.light.text,
              fontFamily: 'HKYavimayan',
              fontSize: { xs: '3rem', md: '4.5rem' },
              mb: 4,
              fontWeight: 'bold'
            }}
          >
            Welcome to LettuceEat
          </Typography>

          <Paper 
            elevation={3} 
            sx={{ 
              p: 4,
              bgcolor: mode === 'dark' 
                ? 'rgba(13, 37, 43, 0.5)' 
                : 'rgba(255, 255, 255, 0.9)',
              maxWidth: 800,
              width: '100%',
              mb: 6,
              borderRadius: 2,
              border: `1px solid ${mode === 'dark' 
                ? 'rgba(255, 255, 255, 0.1)' 
                : 'rgba(0, 0, 0, 0.1)'}`
            }}
          >
            <Typography 
              variant="h4" 
              component="h2"
              sx={{ 
                color: mode === 'dark' ? colors.dark.text : colors.light.text,
                fontFamily: 'HKYavimayan',
                mb: 2
              }}
            >
              Fresh. Local. Sustainable.
            </Typography>
            <Typography 
              variant="h6" 
              sx={{ 
                mb: 3, 
                color: mode === 'dark' ? colors.dark.text : colors.light.text
              }}
            >
              LettuceEat connects you with local farmers and fresh produce, making sustainable eating easier than ever.
            </Typography>
            <Box sx={{ display: 'flex', gap: 2, justifyContent: 'center' }}>
              <Button
                component={RouterLink}
                to="/browse"
                variant="contained"
                size="large"
                sx={{
                  bgcolor: colors.accent.green,
                  color: '#FFFFFF',
                  '&:hover': {
                    bgcolor: colors.accent.yellow
                  }
                }}
              >
                Start Shopping
              </Button>
              <Button
                component={RouterLink}
                to="/merchant/apply"
                variant="outlined"
                size="large"
                sx={{
                  color: colors.accent.green,
                  borderColor: colors.accent.green,
                  '&:hover': {
                    borderColor: colors.accent.yellow,
                    color: colors.accent.yellow
                  }
                }}
              >
                Become a Vendor
              </Button>
            </Box>
          </Paper>

          <VisionSection />

          <Grid container spacing={4}>
            {features.map((feature) => {
              const Icon = feature.icon;
              return (
                <Grid item xs={12} md={4} key={feature.title}>
                  <Paper 
                    sx={{ 
                      p: 4,
                      height: '100%',
                      bgcolor: mode === 'dark' 
                        ? 'rgba(13, 37, 43, 0.5)' 
                        : 'rgba(255, 255, 255, 0.9)',
                      borderRadius: 2,
                      border: `1px solid ${mode === 'dark' 
                        ? 'rgba(255, 255, 255, 0.1)' 
                        : 'rgba(0, 0, 0, 0.1)'}`,
                      transition: 'all 0.3s ease',
                      '&:hover': {
                        transform: 'translateY(-5px)',
                        bgcolor: mode === 'dark'
                          ? 'rgba(13, 37, 43, 0.8)'
                          : 'rgba(255, 255, 255, 1)'
                      }
                    }}
                  >
                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center' }}>
                      <Icon sx={{ fontSize: 48, color: colors.accent.green, mb: 2 }} />
                      <Typography 
                        variant="h5" 
                        component="h3"
                        sx={{ 
                          color: mode === 'dark' ? colors.dark.text : colors.light.text,
                          fontFamily: 'HKYavimayan',
                          mb: 1
                        }}
                      >
                        {feature.title}
                      </Typography>
                      <Typography 
                        variant="body1" 
                        sx={{ 
                          color: mode === 'dark' ? colors.dark.text : colors.light.text
                        }}
                      >
                        {feature.description}
                      </Typography>
                    </Box>
                  </Paper>
                </Grid>
              );
            })}
          </Grid>
        </Box>
      </Container>
    </Box>
  );
};

export default Home;
