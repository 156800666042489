import React, { createContext, useContext, useState, useEffect } from 'react';
import { User } from '@supabase/supabase-js';
import { supabase } from '../lib/supabase';
import { PostgrestResponse, PostgrestSingleResponse } from '@supabase/supabase-js';

interface AuthUser extends User {
  user_role?: string;
}

interface AuthContextType {
  user: AuthUser | null;
  loading: boolean;
  error: string | null;
}

interface Profile {
  id: string;
  email: string;
  user_role: string;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};

export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [user, setUser] = useState<AuthUser | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  // Function to fetch user role with timeout
  const fetchUserRole = async (userId: string): Promise<string | null> => {
    console.log('Fetching user role for:', userId);
    try {
      const { data, error } = await supabase
        .from('user_roles')
        .select('role')
        .eq('user_id', userId)
        .single();

      if (error) {
        console.error('Error fetching user role:', error);
        return null;
      }

      console.log('User role data:', data);
      return data?.role || 'user'; // Default to 'user' if no role found
    } catch (error) {
      console.error('Error in fetchUserRole:', error);
      return null;
    }
  };

  // Function to update user with role
  const updateUserWithRole = async (supabaseUser: User | null) => {
    console.log('Starting updateUserWithRole for user:', supabaseUser?.id);
    if (!supabaseUser) {
      console.log('No user provided to updateUserWithRole');
      setUser(null);
      setLoading(false);
      return;
    }

    try {
      console.log('Fetching role for user:', supabaseUser.id);
      const userRole = await fetchUserRole(supabaseUser.id);
      console.log('Fetched user role:', userRole);

      const userWithRole = {
        ...supabaseUser,
        user_role: userRole || 'user'
      };

      console.log('Setting user with role:', userWithRole);
      setUser(userWithRole);
    } catch (error) {
      console.error('Error in updateUserWithRole:', error);
      // Still set the user but with default role
      setUser({
        ...supabaseUser,
        user_role: 'user'
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    console.log('AuthProvider useEffect running');
    let mounted = true;

    const initialize = async () => {
      try {
        const { data: { session } } = await supabase.auth.getSession();
        console.log('Initial session found:', session ? 'Session exists' : 'No session');
        
        if (session?.user && mounted) {
          console.log('Initial session found, updating user');
          await updateUserWithRole(session.user);
        } else if (mounted) {
          setUser(null);
          setLoading(false);
        }
      } catch (error) {
        console.error('Error in initialize:', error);
        if (mounted) {
          setError('Failed to initialize auth');
          setLoading(false);
        }
      }
    };

    initialize();

    // Set up auth state change listener
    console.log('Setting up auth state change listener');
    const { data: { subscription } } = supabase.auth.onAuthStateChange(
      async (event, session) => {
        console.log('Auth state changed:', event);
        console.log('New session:', session ? 'Session exists' : 'No session');

        if (mounted) {
          if (event === 'SIGNED_OUT') {
            setUser(null);
            setLoading(false);
          } else if (session?.user) {
            await updateUserWithRole(session.user);
          }
        }
        
        console.log('Setting loading to false for event:', event);
        if (mounted) {
          setLoading(false);
        }
      }
    );

    return () => {
      mounted = false;
      subscription.unsubscribe();
    };
  }, []);

  const value = {
    user,
    loading,
    error
  };

  return (
    <AuthContext.Provider value={value}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
