import React from 'react';
import { 
  Grid, 
  Paper, 
  Typography, 
  Box,
  Card,
  CardContent,
  LinearProgress
} from '@mui/material';
import {
  ShoppingCart as OrdersIcon,
  Inventory as ProductsIcon,
  Star as RatingIcon,
  TrendingUp as RevenueIcon
} from '@mui/icons-material';

const MerchantDashboard: React.FC = () => {
  // TODO: Replace with actual data from API
  const stats = [
    {
      title: 'Total Orders',
      value: '24',
      icon: OrdersIcon,
      color: '#4CAF50',
      change: '+12%'
    },
    {
      title: 'Active Products',
      value: '45',
      icon: ProductsIcon,
      color: '#2196F3',
      change: '+5%'
    },
    {
      title: 'Average Rating',
      value: '4.8',
      icon: RatingIcon,
      color: '#FFC107',
      change: '+0.2'
    },
    {
      title: 'Revenue',
      value: '$2,450',
      icon: RevenueIcon,
      color: '#9C27B0',
      change: '+18%'
    }
  ];

  const recentOrders = [
    { id: '1', customer: 'John Doe', total: '$125', status: 'Pending' },
    { id: '2', customer: 'Jane Smith', total: '$89', status: 'Processing' },
    { id: '3', customer: 'Bob Johnson', total: '$245', status: 'Completed' },
  ];

  const lowStockProducts = [
    { name: 'Organic Lettuce', stock: 5, threshold: 10 },
    { name: 'Fresh Tomatoes', stock: 8, threshold: 15 },
    { name: 'Baby Spinach', stock: 3, threshold: 10 },
  ];

  return (
    <Box>
      <Typography variant="h4" gutterBottom>
        Dashboard Overview
      </Typography>

      {/* Stats Cards */}
      <Grid container spacing={3} mb={4}>
        {stats.map((stat) => (
          <Grid item xs={12} sm={6} md={3} key={stat.title}>
            <Paper
              sx={{
                p: 2,
                display: 'flex',
                flexDirection: 'column',
                height: 140,
                position: 'relative',
                overflow: 'hidden'
              }}
            >
              <Box
                sx={{
                  position: 'absolute',
                  top: -15,
                  right: -15,
                  backgroundColor: stat.color,
                  borderRadius: '50%',
                  width: 70,
                  height: 70,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  opacity: 0.2
                }}
              >
                <stat.icon sx={{ fontSize: 40, color: 'white' }} />
              </Box>
              <Typography color="textSecondary" variant="subtitle2">
                {stat.title}
              </Typography>
              <Typography variant="h4" sx={{ mt: 1 }}>
                {stat.value}
              </Typography>
              <Typography
                variant="body2"
                sx={{
                  color: stat.change.startsWith('+') ? 'success.main' : 'error.main',
                  mt: 1
                }}
              >
                {stat.change} from last month
              </Typography>
            </Paper>
          </Grid>
        ))}
      </Grid>

      {/* Recent Orders and Low Stock Products */}
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Card>
            <CardContent>
              <Typography variant="h6" gutterBottom>
                Recent Orders
              </Typography>
              {recentOrders.map((order) => (
                <Box
                  key={order.id}
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    mb: 2,
                    p: 1,
                    bgcolor: 'background.default',
                    borderRadius: 1
                  }}
                >
                  <Box>
                    <Typography variant="subtitle2">{order.customer}</Typography>
                    <Typography variant="body2" color="textSecondary">
                      Order #{order.id}
                    </Typography>
                  </Box>
                  <Box textAlign="right">
                    <Typography variant="subtitle2">{order.total}</Typography>
                    <Typography
                      variant="body2"
                      sx={{
                        color: 
                          order.status === 'Completed'
                            ? 'success.main'
                            : order.status === 'Processing'
                            ? 'warning.main'
                            : 'info.main'
                      }}
                    >
                      {order.status}
                    </Typography>
                  </Box>
                </Box>
              ))}
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} md={6}>
          <Card>
            <CardContent>
              <Typography variant="h6" gutterBottom>
                Low Stock Alert
              </Typography>
              {lowStockProducts.map((product) => (
                <Box key={product.name} sx={{ mb: 2 }}>
                  <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
                    <Typography variant="subtitle2">{product.name}</Typography>
                    <Typography variant="body2" color="error">
                      {product.stock} left
                    </Typography>
                  </Box>
                  <LinearProgress
                    variant="determinate"
                    value={(product.stock / product.threshold) * 100}
                    sx={{
                      height: 8,
                      borderRadius: 5,
                      bgcolor: 'grey.200',
                      '& .MuiLinearProgress-bar': {
                        bgcolor: 
                          product.stock < product.threshold / 3
                            ? 'error.main'
                            : product.stock < product.threshold / 2
                            ? 'warning.main'
                            : 'success.main'
                      }
                    }}
                  />
                </Box>
              ))}
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
};

export default MerchantDashboard;
