import React from 'react';
import { BrowserRouter, Routes, Route, Navigate, Outlet, useLocation } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ThemeProvider } from './theme/ThemeContext';
import { Box, CircularProgress, Typography, CssBaseline } from '@mui/material';

import { AuthProvider, useAuth } from './contexts/AuthContext';
import MainLayout from './components/Layout/MainLayout';
import Login from './pages/Auth/Login';
import Register from './pages/Auth/Register';
import ForgotPassword from './pages/Auth/ForgotPassword';
import ResetPassword from './pages/Auth/ResetPassword';
import AuthCallback from './pages/Auth/AuthCallback';
import Home from './pages/Home';
import Profile from './pages/Profile';
import Orders from './pages/Orders';
import Support from './pages/Support';
import AdminLayout from './components/Admin/AdminLayout';
import Dashboard from './pages/Admin/Dashboard';
import Users from './pages/Admin/Users';
import Applications from './pages/Admin/Applications';
import AuditLogs from './pages/Admin/AuditLogs';
import VendorApplication from './pages/Merchant/VendorApplication';
import ApplicationStatus from './pages/Merchant/ApplicationStatus';
import VendorApplications from './pages/Admin/VendorApplications';
import MerchantLayout from './components/Merchant/MerchantLayout';
import MerchantDashboard from './pages/Merchant/Dashboard';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 1,
      refetchOnWindowFocus: false,
      staleTime: 5 * 60 * 1000, // 5 minutes
    },
  },
});

interface ProtectedRouteProps {
  children: React.ReactNode;
}

const ProtectedRoute = ({ children }: ProtectedRouteProps): JSX.Element => {
  const { user, loading } = useAuth();
  const location = useLocation();
  
  if (loading) {
    return (
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        minHeight="100vh"
        p={3}
      >
        <CircularProgress />
        <Typography variant="body1" style={{ marginTop: 16 }} align="center">
          Loading...
        </Typography>
      </Box>
    );
  }

  if (!user) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return <>{children}</>;
};

const ProtectedAdminRoute = ({ children }: ProtectedRouteProps): JSX.Element => {
  const { user, loading } = useAuth();
  const location = useLocation();
  
  if (loading) {
    return (
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        minHeight="100vh"
        p={3}
      >
        <CircularProgress />
        <Typography variant="body1" style={{ marginTop: 16 }} align="center">
          Loading...
        </Typography>
      </Box>
    );
  }

  if (!user || user.user_role !== 'admin') {
    return <Navigate to="/" state={{ from: location }} replace />;
  }

  return <>{children}</>;
};

const App = () => {
  return (
    <ThemeProvider>
      <CssBaseline />
      <QueryClientProvider client={queryClient}>
        <AuthProvider>
          <BrowserRouter>
            <Box sx={{ 
              minHeight: '100vh',
              bgcolor: 'background.default',
              color: 'text.primary'
            }}>
              <Routes>
                {/* Public routes */}
                <Route path="/login" element={<Login />} />
                <Route path="/register" element={<Register />} />
                <Route path="/forgot-password" element={<ForgotPassword />} />
                <Route path="/reset-password" element={<ResetPassword />} />
                <Route path="/auth/callback" element={<AuthCallback />} />

                {/* Protected routes */}
                <Route path="/" element={<MainLayout />}>
                  <Route index element={<Home />} />
                  <Route path="profile" element={<ProtectedRoute><Profile /></ProtectedRoute>} />
                  <Route path="orders" element={<ProtectedRoute><Orders /></ProtectedRoute>} />
                  <Route path="support" element={<ProtectedRoute><Support /></ProtectedRoute>} />
                </Route>

                {/* Admin routes */}
                <Route path="/admin" element={<ProtectedAdminRoute><AdminLayout /></ProtectedAdminRoute>}>
                  <Route index element={<Dashboard />} />
                  <Route path="users" element={<Users />} />
                  <Route path="applications" element={<Applications />} />
                  <Route path="vendor-applications" element={<VendorApplications />} />
                  <Route path="audit-logs" element={<AuditLogs />} />
                </Route>

                {/* Merchant routes */}
                <Route path="/merchant" element={
                  <ProtectedRoute>
                    <MerchantLayout>
                      <Outlet />
                    </MerchantLayout>
                  </ProtectedRoute>
                }>
                  <Route index element={<MerchantDashboard />} />
                  <Route path="application" element={<VendorApplication />} />
                  <Route path="application-status" element={<ApplicationStatus />} />
                </Route>

                {/* Fallback route */}
                <Route path="*" element={<Navigate to="/" replace />} />
              </Routes>
            </Box>
          </BrowserRouter>
        </AuthProvider>
      </QueryClientProvider>
    </ThemeProvider>
  );
};

export default App;
