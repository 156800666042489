import React, { useState } from 'react';
import {
  Box,
  Container,
  Typography,
  Paper,
  TextField,
  Button,
  Link,
  Alert,
  useTheme,
  Divider,
} from '@mui/material';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import GoogleIcon from '@mui/icons-material/Google';
import { supabase } from '../../lib/supabase';
import { AUTH_CALLBACK_URL } from '../../config/urls';

const Register: React.FC = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState(false);

  const handleGoogleSignUp = async () => {
    try {
      setLoading(true);
      setError(null);
      
      const { data, error } = await supabase.auth.signInWithOAuth({
        provider: 'google',
        options: {
          queryParams: {
            access_type: 'offline',
            prompt: 'consent'
          },
          redirectTo: window.location.origin
        }
      });

      if (error) throw error;
      if (data.url) {
        window.location.href = data.url;
      }
    } catch (err) {
      console.error('Google sign up error:', err);
      setError(err instanceof Error ? err.message : 'An error occurred during sign up');
    } finally {
      setLoading(false);
    }
  };

  const handleEmailSignUp = async (e: React.FormEvent) => {
    e.preventDefault();
    
    if (password !== confirmPassword) {
      setError('Passwords do not match');
      return;
    }

    try {
      setLoading(true);
      setError(null);

      const { error: signUpError } = await supabase.auth.signUp({
        email,
        password,
        options: {
          data: {
            user_role: 'consumer'
          },
          emailRedirectTo: AUTH_CALLBACK_URL
        }
      });

      if (signUpError) throw signUpError;

      setSuccess(true);
      setTimeout(() => {
        navigate('/login');
      }, 5000); // Redirect to login after 5 seconds
    } catch (err) {
      console.error('Email sign up error:', err);
      setError(err instanceof Error ? err.message : 'An error occurred during sign up');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box sx={{ 
      minHeight: '100vh',
      bgcolor: theme.palette.background.default,
      color: theme.palette.text.primary,
      pt: 8
    }}>
      <Container maxWidth="sm">
        <Box sx={{ 
          display: 'flex', 
          flexDirection: 'column', 
          alignItems: 'center',
          gap: 4
        }}>
          <Typography 
            component="h1" 
            variant="h2" 
            align="center" 
            sx={{ 
              fontFamily: 'HKYavimayan',
              color: theme.palette.primary.main,
              mb: 4
            }}
          >
            Create Account
          </Typography>

          {success ? (
            <Paper 
              elevation={3} 
              sx={{ 
                p: 4,
                width: '100%',
                bgcolor: theme.palette.background.paper,
                borderRadius: 2,
                textAlign: 'center'
              }}
            >
              <Typography variant="h6" color="primary" gutterBottom>
                Registration Successful!
              </Typography>
              <Typography variant="body1" paragraph>
                Please check your email for the verification link.
              </Typography>
              <Typography variant="body2" color="text.secondary">
                You will be redirected to the login page in a few seconds...
              </Typography>
            </Paper>
          ) : (
            <Paper 
              elevation={3} 
              sx={{ 
                p: 4,
                width: '100%',
                bgcolor: theme.palette.background.paper,
                borderRadius: 2
              }}
            >
              {error && (
                <Alert severity="error" sx={{ mb: 3 }}>
                  {error}
                </Alert>
              )}

              <Button
                fullWidth
                variant="outlined"
                startIcon={<GoogleIcon />}
                onClick={handleGoogleSignUp}
                disabled={loading}
                sx={{ mb: 3 }}
              >
                Sign up with Google
              </Button>

              <Divider sx={{ mb: 3 }}>or</Divider>

              <Box 
                component="form" 
                onSubmit={handleEmailSignUp}
                sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}
              >
                <TextField
                  required
                  fullWidth
                  label="Email"
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  autoComplete="email"
                  variant="outlined"
                  disabled={loading}
                />
                
                <TextField
                  required
                  fullWidth
                  label="Password"
                  type="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  autoComplete="new-password"
                  variant="outlined"
                  disabled={loading}
                />

                <TextField
                  required
                  fullWidth
                  label="Confirm Password"
                  type="password"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  autoComplete="new-password"
                  variant="outlined"
                  disabled={loading}
                />

                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  size="large"
                  disabled={loading}
                  sx={{ 
                    bgcolor: theme.palette.primary.main,
                    color: '#fff',
                    '&:hover': {
                      bgcolor: theme.palette.primary.dark,
                    }
                  }}
                >
                  {loading ? 'Creating Account...' : 'Create Account'}
                </Button>

                <Box sx={{ textAlign: 'center' }}>
                  <Link 
                    component={RouterLink} 
                    to="/login"
                    sx={{ color: theme.palette.secondary.main }}
                  >
                    Already have an account? Login
                  </Link>
                </Box>
              </Box>
            </Paper>
          )}
        </Box>
      </Container>
    </Box>
  );
};

export default Register;
