import React, { useState, ChangeEvent } from 'react';
import {
  Container,
  Typography,
  TextField,
  Button,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Paper,
  Snackbar,
  Alert,
  FormHelperText
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { CloudUpload } from '@mui/icons-material';
import axios from 'axios';
import { APP_URL } from '../../config/urls';

interface VendorApplicationForm {
  businessName: string;
  farmLocation: string;
  productTypes: string[];
  certifications: string;
  contactEmail: string;
  yearsInOperation: string;
  websiteOrSocial: string;
  phoneNumber: string;
  expectedMonthlyVolume: string;
  farmType: string;
  farmPhotos: File[];
}

const VendorApplication: React.FC = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState<VendorApplicationForm>({
    businessName: '',
    farmLocation: '',
    productTypes: [],
    certifications: '',
    contactEmail: '',
    yearsInOperation: '',
    websiteOrSocial: '',
    phoneNumber: '',
    expectedMonthlyVolume: '',
    farmType: '',
    farmPhotos: []
  });
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success' as 'success' | 'error',
  });

  const productTypeOptions = [
    'Vegetables',
    'Fruits',
    'Dairy',
    'Meat',
    'Eggs',
    'Herbs',
    'Other',
  ];

  const farmTypeOptions = [
    'Small Farm (< 50 acres)',
    'Medium Farm (50-500 acres)',
    'Large Farm (> 500 acres)',
    'Urban Garden',
    'Greenhouse',
    'Hydroponic',
    'Backyard Garden',
    'Other'
  ];

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleProductTypesChange = (event: any) => {
    setFormData({
      ...formData,
      productTypes: event.target.value,
    });
  };

  const handlePhotoUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      const newPhotos = Array.from(event.target.files);
      setFormData({
        ...formData,
        farmPhotos: [...formData.farmPhotos, ...newPhotos].slice(0, 5) // Limit to 5 photos
      });
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      // Create FormData to handle file uploads
      const submitData = new FormData();
      Object.entries(formData).forEach(([key, value]) => {
        if (key === 'farmPhotos') {
          value.forEach((photo: File, index: number) => {
            submitData.append(`farmPhotos`, photo);
          });
        } else if (key === 'productTypes') {
          submitData.append(key, JSON.stringify(value));
        } else {
          submitData.append(key, value as string);
        }
      });

      await axios.post(`${APP_URL}/api/vendor/apply`, submitData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      
      setSnackbar({
        open: true,
        message: 'Application submitted successfully!',
        severity: 'success',
      });
      setTimeout(() => {
        navigate('/merchant/application-status');
      }, 2000);
    } catch (error) {
      setSnackbar({
        open: true,
        message: 'Error submitting application. Please try again.',
        severity: 'error',
      });
    }
  };

  return (
    <Container maxWidth="md">
      <Paper elevation={3} sx={{ p: 4, mt: 4 }}>
        <Typography variant="h4" gutterBottom>
          Vendor Application
        </Typography>
        <Typography variant="body1" color="text.secondary" sx={{ mb: 4 }}>
          Join our community of local farmers and producers. Please provide accurate information to help us verify your business.
        </Typography>

        <form onSubmit={handleSubmit}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                label="Business Name"
                name="businessName"
                value={formData.businessName}
                onChange={handleChange}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                label="Farm Location"
                name="farmLocation"
                value={formData.farmLocation}
                onChange={handleChange}
                placeholder="Full address including city and state"
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <TextField
                required
                fullWidth
                label="Years in Operation"
                name="yearsInOperation"
                type="number"
                value={formData.yearsInOperation}
                onChange={handleChange}
                inputProps={{ min: 0 }}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <TextField
                required
                fullWidth
                label="Phone Number"
                name="phoneNumber"
                value={formData.phoneNumber}
                onChange={handleChange}
                placeholder="(123) 456-7890"
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                label="Contact Email"
                name="contactEmail"
                type="email"
                value={formData.contactEmail}
                onChange={handleChange}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Website or Social Media Link"
                name="websiteOrSocial"
                value={formData.websiteOrSocial}
                onChange={handleChange}
                placeholder="https://..."
              />
            </Grid>

            <Grid item xs={12}>
              <FormControl fullWidth required>
                <InputLabel>Farm Type</InputLabel>
                <Select
                  value={formData.farmType}
                  onChange={(e) => setFormData({ ...formData, farmType: e.target.value })}
                  label="Farm Type"
                  name="farmType"
                >
                  {farmTypeOptions.map((type) => (
                    <MenuItem key={type} value={type}>
                      {type}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12}>
              <FormControl fullWidth required>
                <InputLabel>Product Types</InputLabel>
                <Select
                  multiple
                  value={formData.productTypes}
                  onChange={handleProductTypesChange}
                  label="Product Types"
                >
                  {productTypeOptions.map((type) => (
                    <MenuItem key={type} value={type}>
                      {type}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Expected Monthly Volume"
                name="expectedMonthlyVolume"
                value={formData.expectedMonthlyVolume}
                onChange={handleChange}
                placeholder="e.g., 500 lbs of vegetables, 200 dozen eggs"
                helperText="Estimate your expected monthly sales volume"
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Certifications"
                name="certifications"
                value={formData.certifications}
                onChange={handleChange}
                multiline
                rows={3}
                placeholder="List any relevant certifications (organic, GAP, etc.)"
              />
            </Grid>

            <Grid item xs={12}>
              <FormControl fullWidth>
                <input
                  accept="image/*"
                  style={{ display: 'none' }}
                  id="farm-photos"
                  type="file"
                  multiple
                  onChange={handlePhotoUpload}
                />
                <label htmlFor="farm-photos">
                  <Button
                    component="span"
                    variant="outlined"
                    startIcon={<CloudUpload />}
                    fullWidth
                  >
                    Upload Farm Photos
                  </Button>
                </label>
                <FormHelperText>
                  Upload up to 5 photos of your farm/produce (optional)
                </FormHelperText>
                {formData.farmPhotos.length > 0 && (
                  <Typography variant="body2" sx={{ mt: 1 }}>
                    {formData.farmPhotos.length} photo(s) selected
                  </Typography>
                )}
              </FormControl>
            </Grid>

            <Grid item xs={12}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                size="large"
                fullWidth
              >
                Submit Application
              </Button>
            </Grid>
          </Grid>
        </form>
      </Paper>
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
      >
        <Alert severity={snackbar.severity}>{snackbar.message}</Alert>
      </Snackbar>
    </Container>
  );
};

export default VendorApplication;
