import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Typography, CircularProgress } from '@mui/material';
import { useAuth } from '../../contexts/AuthContext';
import { supabase } from '../../lib/supabase';

const AuthCallback: React.FC = () => {
  const navigate = useNavigate();
  const { user, loading } = useAuth();
  const [error, setError] = useState<string | null>(null);
  const [attempts, setAttempts] = useState(0);

  useEffect(() => {
    const handleCallback = async () => {
      try {
        console.log('AuthCallback: Starting callback processing');
        
        // Get the URL hash parameters
        const hashParams = new URLSearchParams(window.location.hash.slice(1));
        const accessToken = hashParams.get('access_token');
        const refreshToken = hashParams.get('refresh_token');
        
        console.log('AuthCallback: URL parameters:', {
          hasAccessToken: !!accessToken,
          hasRefreshToken: !!refreshToken,
          hash: window.location.hash
        });

        if (accessToken) {
          console.log('AuthCallback: Setting session from tokens');
          const { error: setSessionError } = await supabase.auth.setSession({
            access_token: accessToken,
            refresh_token: refreshToken || ''
          });

          if (setSessionError) {
            console.error('AuthCallback: Error setting session:', setSessionError);
            throw setSessionError;
          }
        }

        // Get the current session
        const { data: { session }, error: sessionError } = await supabase.auth.getSession();
        
        if (sessionError) {
          console.error('AuthCallback: Session error:', sessionError);
          throw sessionError;
        }

        if (!session) {
          console.log('AuthCallback: No session found, checking URL parameters');
          if (!accessToken) {
            throw new Error('No session or access token found');
          }
        } else {
          console.log('AuthCallback: Session found, user:', session.user.id);
        }

        // If we have a session but no user in context yet, wait and retry
        if (!user && !loading) {
          console.log('AuthCallback: Waiting for user context, attempt:', attempts);
          if (attempts < 5) { // Try up to 5 times
            setTimeout(() => setAttempts(prev => prev + 1), 1000);
            return;
          }
          // If we've tried 5 times and still no user, try refreshing the page
          console.log('AuthCallback: Max attempts reached, refreshing page');
          window.location.href = '/';
          return;
        }

        if (user) {
          console.log('AuthCallback: Success, user in context:', user.id);
          navigate('/', { replace: true });
        }
      } catch (err) {
        console.error('AuthCallback: Error:', err);
        setError('Authentication failed. Please try logging in again.');
        setTimeout(() => navigate('/login', { replace: true }), 3000);
      }
    };

    handleCallback();
  }, [user, loading, navigate, attempts]);

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      minHeight="100vh"
      p={3}
    >
      {error ? (
        <>
          <Typography color="error" gutterBottom>
            {error}
          </Typography>
          <Typography variant="body2">
            Redirecting to login...
          </Typography>
        </>
      ) : (
        <>
          <CircularProgress sx={{ color: '#90EE90' }} />
          <Typography variant="body1" sx={{ mt: 2 }}>
            {attempts > 0 ? `Completing sign in (attempt ${attempts}/5)...` : 'Completing sign in...'}
          </Typography>
        </>
      )}
    </Box>
  );
};

export default AuthCallback;
